<template>
  <ValidationObserver rules="required" v-slot="{ invalid }">
    <RightSideBar
      v-if="openModal"
      :loading="loading"
      :disabled-button="invalid"
      submit="Save"
      @submit="updateClick"
      @close="close"
      button-class="bg-dynamicBackBtn text-white"
    >
      <template v-slot:title>
        <h1 class="text-xl text-left font-bold flex -mt-2">
          {{ scheduleId ? "Edit Pay Schedule" : "New Pay Schedule" }}
        </h1>
      </template>
      <div>
        <div>
          <div class="mb-4">
            <c-select
              placeholder="--Select--"
              label="Pay Type"
              :rules="['required']"
              :disabled="scheduleId !== ''"
              v-model="paySchedule.payrollSchedule.payType"
              :options="[
                { name: 'Salaried', id: 'salaried' }
              ]"
            />
          </div>
          <p class="text-darkPurple">Select work days</p>
          <div class="flex flex-wrap mt-2 gap-3">
            <card
              v-for="(day, index) in weeklyWorkingDays"
              :key="index"
              class="mr-2 p-2 text-sm capitalize"
              style="width: 21%"
            >
              <checkbox
                :label="day.caption"
                checkbox-size="height:16px; width:16px;
            margin-top: 2px;"
                :value="day.value"
                v-model="selectedDays"
                @change="getSelected($event, index)"
              />
            </card>
          </div>
          <div class="my-4">
            <c-select
              placeholder="--Select--"
              label="Pay Frequency"
              :options="filteredPayFrequency"
              :rules="['required']"
              :disabled="
                !paySchedule.payrollSchedule.payType ||
                scheduleId !== '' ||
                filteredPayFrequency.length < 1
              "
              @onChange="handlePayFrequencyChange"
              v-model="paySchedule.payrollSchedule.payFrequency"
            />
            <small
              v-if="
                paySchedule.payrollSchedule.payType === 'salaried' &&
                filteredPayFrequency.length < 1 &&
                scheduleId === ''
              "
              class="text-flame font-extrabold"
              >You have set up all pay frequencies.</small
            >
          </div>
          <div class="flex mb-4" v-for="(data, index) in payDates" :key="index">
            <div class="w-1/2">
              <label class="date-label">{{
                data.showOrdinal
                  ? ` ${$getOrdinal(index + 1)} Pay Date`
                  : "Pay Date"
              }}</label>
              <div class="mb-4">
                <c-select
                  placeholder="--Select--"
                  :options="paymentDay"
                  :rules="['required']"
                  v-model="data.payDay"
                />
              </div>
            </div>
            <div class="w-1/2 block">
              <div class="w-full ml-4">
                <c-text
                  placeholder="%"
                  label="Pay Split %"
                  type="number"
                  :disabled="
                    paySchedule.payrollSchedule.payFrequency === 'monthly'
                  "
                  :rules="['required']"
                  @input="handleSplitChange"
                  v-model="data.splitPercent"
                />
              </div>
              <small class="text-red-700 flex ml-5" v-if="showError"
                >Pay split cannot be greater or less than 100%</small
              >
            </div>
          </div>

          <div class="w-full mt-5 flex flex-col">
            <div class="w-full flex">
              <div class="w-1/2 mr-8">
                <p class="text-sm text-darkPurple my-2 font-normal">
                  Work Hours Per Day
                </p>
                <div class="w-full spac flex">
                  <c-select
                    placeholder="--Select--"
                    :options="numbers"
                    :rules="['required']"
                    style="width: 50%"
                    v-model="paySchedule.payrollSchedule.hoursPerDay"
                  />
                  <c-text
                    value="Per Day"
                    style="width: 50%; margin-left: 8px"
                    disabled
                  />
                </div>
              </div>
              <div class="w-1/2">
                <p class="text-sm text-darkPurple my-2 font-normal">
                  Weeks Per Year
                </p>
                <div class="flex">
                  <c-select
                    placeholder="--Select--"
                    style="width: 50%"
                    :options="weeksPerYear"
                    :rules="['required']"
                    v-model="paySchedule.payrollSchedule.weeksPerYear"
                  />
                  <c-text
                    value="Weeks"
                    style="width: 50%; margin-left: 8px"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="w-full mt-4">
              <Alert
                message="52 weeks is standard. However, you may choose to use 52.175 weeks if
                   you want to accomodate fluctuations in a Calender year i.e leap
                   years and non-leap years."
                variant="primary"
                :time="2"
                style="
                  position: relative;
                  right: 0;
                  top: 10px;
                  background: rgba(244, 213, 176, 0.15);
                  border: 1px solid #e99323;
                "
              />
            </div>
          </div>
          <div class="border w-full mt-8" />

          <card class="p-5 mt-1">
            <p class="text-lg font-bold text-darkPurple">Holiday Policy</p>
            <div class="w-full mt-4 flex flex-col">
              <p class="text-base text-jet">
                When Payday falls on a non-working day or holiday, employees
                would get paid on ?
              </p>
              <div class="w-full flex mt-2">
                <div class="flex w-full">
                  <radio-button
                    :options="holidayPolicy"
                    :value="paySchedule.payrollSchedule.holidayPolicy"
                    col-span="col-span-12"
                    class="ml-2 text-base text-darkPurple"
                    row-gap="gap-y-2"
                    space-between="mr-3"
                    v-model="paySchedule.payrollSchedule.holidayPolicy"
                  />
                </div>
              </div>
            </div>
            <div class="w-full mt-8 flex">
              <Alert
                message="When payday falls on a holiday, employees
          would get paid on the previous working day"
                variant="primary"
                :time="2"
                style="
                  position: relative;
                  right: 0;
                  top: -5px;
                  box-shadow: none;
                  background: rgba(244, 213, 176, 0.15);
                  border: 1px solid #e99323;
                "
              />
            </div>
          </card>
        </div>
      </div>
    </RightSideBar>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import Alert from "@scelloo/cloudenly-ui/src/components/alert";
import Card from "@/components/Card";
import RadioButton from "@/components/RadioButton";
import RightSideBar from "@/components/RightSideBar";
import Checkbox from "@/components/Checkbox";

export default {
  name: "PaySchedule",
  components: {
    Card,
    Checkbox,
    RadioButton,
    CSelect,
    RightSideBar,
    CText,
    Alert,
    ValidationObserver,
  },
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
    scheduleId: {
      type: String,
      default: "",
    },
    salaried: {
      type: Array,
      default: () => [],
    },
    hourly: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      disableBtn: false,
      disabled: false,
      enabledView: false,
      loading: false,
      showError: false,
      payDates: [],
      paySchedule: {
        payrollSchedule: {
          weeklyWorkingDays: [],
          workdaysPerWeek: "",
          hoursPerDay: "",
          weeksPerYear: "",
          monthlyCalendarUses: "",
          payType: null,
          payFrequency: null,
          holidayPolicy: "sameDay",
          paySplits: [],
        },
        payrunSchedule: {
          timeOfMonthForPayment: "",
          firstPayrunMonth: "",
          firstPayrunYear: "",
          deductEmployeeTax: "",
          paymentDay: "10",
        },
      },
      payFrequency: [
        {
          id: "monthly",
          name: "Monthly",
        },
        {
          id: "twice_monthly",
          name: "Twice Monthly",
        },
        {
          id: "weekly",
          name: "Four Times Monthly",
        },
      ],
      selectedDays: [],
      weekDays: [],
      actualDays: [
        {
          name: "Actual days in a month",
          radioName: "days",
          value: "actualDays",
        },
        {
          name: "Selected Working Days",
          radioName: "days",
          value: "organizatinDays",
        },
      ],
      paymentMonth: [
        { id: "true", name: "current" },
        { id: "false", name: "following" },
      ],
      holidayPolicy: [
        { name: "The same day", radioName: "holiday", value: "sameDay" },
        {
          name: "The next working day or previous working day (depending on which is closer)",
          radioName: "holiday",
          value: "nextOrPreviousWorkingDay",
        },
      ],
      numbers: [
        { id: 1, name: "1" },
        { id: 2, name: "2" },
        { id: 3, name: "3" },
        { id: 4, name: "4" },
        { id: 5, name: "5" },
        { id: 6, name: "6" },
        { id: 7, name: "7" },
        { id: 8, name: "8" },
        { id: 9, name: "9" },
        { id: 10, name: "10" },
        { id: 11, name: "11" },
        { id: 12, name: "12" },
      ],
      weeksPerYear: [
        { id: 52, name: "52" },
        { id: 52.175, name: "52.175" },
      ],
      payRunMonth: [
        { id: 1, name: "January" },
        { id: 2, name: "February" },
        { id: 3, name: "March" },
        { id: 4, name: "April" },
        { id: 5, name: "May" },
        { id: 6, name: "June" },
        { id: 7, name: "July" },
        { id: 8, name: "August" },
        { id: 9, name: "September" },
        { id: 10, name: "October" },
        { id: 11, name: "November" },
        { id: 12, name: "December" },
      ],
      payRunYear: [
        { id: 2021, name: "2021" },
        { id: 2022, name: "2022" },
        { id: 2023, name: "2023" },
        { id: 2024, name: "2024" },
      ],
      paymentDay: [],
      weeklyWorkingDays: [
        {
          state: true,
          value: 1,
          caption: "monday",
        },
        {
          state: true,
          value: 2,
          caption: "tuesday",
        },
        {
          state: true,
          value: 3,
          caption: "wednesday",
        },
        {
          state: true,
          value: 4,
          caption: "thursday",
        },
        {
          state: true,
          value: 5,
          caption: "friday",
        },
        {
          state: false,
          value: 6,
          caption: "saturday",
        },
        {
          state: false,
          value: 7,
          caption: "sunday",
        },
      ],
      days: [
        {
          id: 1,
          name: "Monday",
        },
        {
          id: 2,
          name: "Tuesday",
        },
        {
          id: 3,
          name: "Wednesday",
        },
        {
          id: 4,
          name: "Thursday",
        },
        {
          id: 5,
          name: "Friday",
        },
        {
          id: 6,
          name: "Saturday",
        },
        {
          id: 7,
          name: "Sunday",
        },
      ],
    };
  },
  watch: {
    scheduleId() {
      if (this.scheduleId && this.openModal) {
        this.selectedDays = []
        this.openPaySchedule();
      }
    },
    responseBg() {
      setTimeout(() => {
        this.response = "";
      }, 7000);
    },
    openModal() {
      this.selectedDays = []
      this.getWeekDays()
    }
  },
  computed: {
    filteredPayFrequency() {
      if(this.$store.state.subscription && this.$store.state.subscription.plan === 'basic'){
        return [{
          id: "monthly",
          name: "Monthly"
        }]
      }
        if (this.scheduleId === "") {
          return this.payFrequency.filter(
            item => !this.salaried.includes(item.id)
          );
        }
        return this.payFrequency;
    }
  },
  methods: {
    getSelected(obj) {
      this.weeklyWorkingDays.forEach((days, index) => {
        const workingDays = days;
        if (days.value !== obj[index]) {
          workingDays.state = false;
        } else {
          workingDays.state = true;
        }
      });
    },

    reset() {
      this.paySchedule = {
        payrollSchedule: {
          weeklyWorkingDays: [],
          workdaysPerWeek: "",
          hoursPerDay: "",
          weeksPerYear: "",
          monthlyCalendarUses: "",
          payType: null,
          payFrequency: null,
          holidayPolicy: "sameDay",
          paySplits: [],
        },
      };
      this.payDates = [];
      this.paymentDay = [];
    },
    close() {
      this.reset();
      this.$emit("close");
    },
    handleSplitChange() {
      let result = 0;
      for (let i = 0; i < this.payDates.length; i++) {
        result += Number(this.payDates[i].splitPercent);
      }
      if (result < 100 || result > 100) {
        this.showError = true;
      } else {
        this.showError = false;
      }
    },
    handlePayFrequencyChange(value) {
      this.payDates = [];
      this.paymentDay = [];
      if (this.payType === "Hourly") {
        this.payDates.push({
          payDay: null,
          splitPercent: null,
          splitPosition: 1,
          showOrdinal: false,
        });
        return;
      }
      switch (value) {
        case "daily":
          this.payDates = [];
          break;
        case "weekly":
          for (let i = 1; i <= 31; i++) {
            this.paymentDay.push({
              name: this.$getOrdinal(i),
              id: Number(i),
            });
          }
          for (let i = 1; i <= 4; i++) {
            this.payDates.push({
              payDay: null,
              splitPercent: 100 / 4,
              splitPosition: i,
              showOrdinal: true,
            });
          }
          break;
        case "monthly":
          for (let i = 1; i <= 31; i++) {
            this.paymentDay.push({
              name: this.$getOrdinal(i),
              id: Number(i),
            });
          }
          this.payDates.push({
            payDay: null,
            splitPercent: 100,
            splitPosition: 1,
            showOrdinal: false,
          });
          break;
        case "twice_monthly":
          for (let i = 1; i <= 31; i++) {
            this.paymentDay.push({
              name: this.$getOrdinal(i),
              id: Number(i),
            });
          }
          for (let i = 1; i <= 2; i++) {
            this.payDates.push({
              payDay: null,
              splitPercent: 100 / 2,
              splitPosition: i,
              showOrdinal: true,
            });
          }
          break;
        default:
          break;
      }
    },
    async updateClick() {
        if (this.scheduleId) {
          this.updatePaySchedule();
        } else {
          this.createPaySchedule();
        }
    },
    createPaySchedule() {
      this.disableBtn = true;
      this.paySchedule.payrollSchedule.weeklyWorkingDays =
        this.weeklyWorkingDays;
      this.paySchedule.payrollSchedule.workdaysPerWeek =
        this.selectedDays.length;
      this.paySchedule.payrollSchedule.paySplitCount = this.payDates.length;
      this.paySchedule.payrollSchedule.paySplits = this.payDates.map(
        (item) => ({
          splitPosition: item.splitPosition,
          payDay: Number(item.payDay),
          splitPercent: item.splitPercent,
        })
      );
      const requestPayload = {
        orgId: this.$orgId,
        ...this.paySchedule.payrollSchedule,
      };
      this.$_createPaySchedule(requestPayload)
        .then(() => {
          this.$emit("close");
          this.$toasted.success("Settings saved successfully", {
            duration: 5000,
          });
          this.disableBtn = false;
          this.reset();
          window.scrollTo({ top: 0, behavior: "smooth" });
        })
        .catch((err) => {
          this.disableBtn = false;
          this.$toasted.error("Error", { duration: 5000 });
          throw new Error(err);
        });
    },
    updatePaySchedule() {
      this.disableBtn = true;
      const paySplit = this.payDates.map((item) => ({
        splitPosition: item.splitPosition,
        payDay: Number(item.payDay),
        splitPercent: item.splitPercent,
        payScheduleId: item.payScheduleId,
        id: item.id,
      }));
      const requestPayload = {
        paySplitCount: this.paySchedule.payrollSchedule.paySplitCount,
        weeklyWorkingDays: this.weeklyWorkingDays,
        hoursPerDAY: this.paySchedule.payrollSchedule.hoursPerDay,
        weeksPerYear: this.paySchedule.payrollSchedule.weeksPerYear,
        workdaysPerWeek: this.selectedDays.length,
        paySplits: paySplit,
        holidayPolicy: this.paySchedule.payrollSchedule.holidayPolicy,
      };
      this.$_updatePaySchedules({
        id: this.scheduleId,
        payload: requestPayload,
      })
        .then(() => {
          this.$toasted.success("Settings saved successfully", {
            duration: 5000,
          });
          this.$emit("close");
          this.disableBtn = false;
          this.reset();
          window.scrollTo({ top: 0, behavior: "smooth" });
        })
        .catch((err) => {
          this.disableBtn = false;
          this.$toasted.error(`${err.message}`, { duration: 6000 });
          throw new Error(err);
        });
    },

    async openPaySchedule() {
      this.getPaySchedule(this.scheduleId);
    },

    getPaySchedule(id) {
      this.reset();
      this.loading = true;
      this.$_getOnePaySchedule(id).then((result) => {
        const { weeklyWorkingDays, hoursPerDAY, paySplits, payFrequency } =
          result.data.data;
        this.weeklyWorkingDays = weeklyWorkingDays;
        this.paySchedule.payrollSchedule = result.data.data;
        this.paySchedule.payrollSchedule.hoursPerDay = hoursPerDAY;
        if (payFrequency === "weekly") {
          this.getOrdinal();
          paySplits.forEach((item) => {
            this.payDates.push({
              ...item,
              showOrdinal: true,
            });
          });
        }
        if (payFrequency === "twice_monthly" || payFrequency === "monthly") {
          this.getOrdinal();
          paySplits.forEach((item) => {
            this.payDates.push({
              ...item,
              showOrdinal: payFrequency === "twice_monthly",
            });
          });
        }
        this.loading = false;
      });
    },

    handleWeekDaysSelection() {
      let newItem;
      return this.weeklyWorkingDays.map((item) => {
        newItem = item;
        if (this.selectedDays.find((selected) => selected === newItem.value)) {
          newItem.state = true;
        } else {
          newItem.state = false;
        }
        return newItem;
      });
    },
    getOrdinal() {
      for (let i = 1; i <= 31; i++) {
        this.paymentDay.push({
          name: this.$getOrdinal(i),
          id: Number(i),
        });
      }
    },
    reverseChanges() {
      this.selectedDays = [];
      this.getPaySchedule("revert");
    },
    getWeekDays() {
      this.weeklyWorkingDays.forEach((selectedweekDay) => {
        if (selectedweekDay.state) {
          this.selectedDays.push(selectedweekDay.value);
        }
      });
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
